import HomeIcon from '@mui/icons-material/Home'
import { ReactElement } from 'react'
import CollectionsIcon from '@mui/icons-material/Collections'
import ApartmentIcon from '@mui/icons-material/Apartment'
// import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'

type MenuProps = {
  label: string,
  link: string,
  available: boolean,
  icon: ReactElement
}

export const MenuItems: MenuProps[] = [
  {
    label: 'Home',
    link: '/',
    available: true,
    icon: <HomeIcon />
  },
  {
    label: 'My Collection',
    link: '/collection',
    available: true,
    icon: <CollectionsIcon />

  },
  {
    label: 'Company',
    link: '/company',
    available: true,
    icon: <ApartmentIcon />

  },
  // {
  //   label: 'Admin',
  //   link: '/admin',
  //   available: true,
  //   icon: <AdminPanelSettingsIcon />
  // },
]
