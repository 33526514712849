import tokenService from "./tokenService"
import axios from 'axios'
import dayjs from 'dayjs'
import { usePrepareContractWrite, useContractWrite } from "wagmi"

export const flat = (array: any[]) => {
  var result: any[] = []
  array.forEach(function(a) {
    result.push(a)
    if (Array.isArray(a.children)) {
      result = result.concat(flat(a.children))
    }
  })
  return result
}

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    tokenService.setAccessToken(accessToken)
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  } else {
    tokenService.removeAccessToken()
    delete axios.defaults.headers.common.Authorization
  }
}

export const maskString = (key: string) => {
  const reg = /^(\w{5})(.*)(\w{5})$/g
  return key.replace(reg, (match, p1, p2, p3) => {
    const maskedMiddle = '*'.repeat(p2.length)
    return `${p1}${maskedMiddle}${p3}`
  })
}

export const formatDate = (key: string) => {
  if (key) {
    return key.split("T")[0]
  } else return ''
}

export const scrollToElement = (id:any) => {
  if (id) {
    if (document.getElementById(id)) {
      document?.getElementById(id)?.scrollIntoView(true)
    }
  }
}

export function formatDateWithoutTime(date: any) {
  if (date) {
    return dayjs(date).format('YYYY-MM-DD')
  } else {
    return date
  }
}

export function roundToTwoDecimalPlaces(num: any) {
  return Math.round(num * 100) / 100
}

export const getPercentageBalance = (total: any, percentage: any) => {
  return ((parseFloat(total) / 100) * parseFloat(percentage)).toFixed(2)
}

export function convertToDayJs(dates: any) {
  if (dates) {
    const date = dayjs(dates, 'YYYY-MM-DD')
    return date
  } else {
    return ''
  }
}

// export const contractaddress = "0x692eb54cfcd56fcf3d5d5a787d618e4e20f0168b"
export const seedContractAddress = "0xcA3A7249deb567552881827Ce8348D106f879A0D"
export const deedContractAddress = "0x89DE1A2376D179eC2673a36d696959D2a24172Cd"

export const abi = [{
  "inputs": [{
    "internalType": "string",
    "name": "_name",
    "type": "string"
  }, {
    "internalType": "string",
    "name": "_symbol",
    "type": "string"
  }, {
    "internalType": "string",
    "name": "_contractUri",
    "type": "string"
  }, {
    "internalType": "address",
    "name": "_initialOwner",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "_seedNftAddress",
    "type": "address"
  }],
  "stateMutability": "nonpayable",
  "type": "constructor"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "sender",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }, {
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }],
  "name": "ERC721IncorrectOwner",
  "type": "error"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "operator",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }],
  "name": "ERC721InsufficientApproval",
  "type": "error"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "approver",
    "type": "address"
  }],
  "name": "ERC721InvalidApprover",
  "type": "error"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "operator",
    "type": "address"
  }],
  "name": "ERC721InvalidOperator",
  "type": "error"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }],
  "name": "ERC721InvalidOwner",
  "type": "error"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "receiver",
    "type": "address"
  }],
  "name": "ERC721InvalidReceiver",
  "type": "error"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "sender",
    "type": "address"
  }],
  "name": "ERC721InvalidSender",
  "type": "error"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }],
  "name": "ERC721NonexistentToken",
  "type": "error"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }],
  "name": "OwnableInvalidOwner",
  "type": "error"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "account",
    "type": "address"
  }],
  "name": "OwnableUnauthorizedAccount",
  "type": "error"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "approved",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }],
  "name": "Approval",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "operator",
    "type": "address"
  }, {
    "indexed": false,
    "internalType": "bool",
    "name": "approved",
    "type": "bool"
  }],
  "name": "ApprovalForAll",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "previousOwner",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "newOwner",
    "type": "address"
  }],
  "name": "OwnershipTransferred",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "from",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }],
  "name": "Transfer",
  "type": "event"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }],
  "name": "approve",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }],
  "name": "balanceOf",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256[]",
    "name": "seedTokenIds",
    "type": "uint256[]"
  }, {
    "internalType": "string[]",
    "name": "tokenUris",
    "type": "string[]"
  }, {
    "internalType": "address[]",
    "name": "receivers",
    "type": "address[]"
  }],
  "name": "bulkMint",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256[]",
    "name": "tokenIds",
    "type": "uint256[]"
  }, {
    "internalType": "address",
    "name": "receiver",
    "type": "address"
  }],
  "name": "bulkTransfer",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "contractURI",
  "outputs": [{
    "internalType": "string",
    "name": "",
    "type": "string"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_tokenAddress",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "_amount",
    "type": "uint256"
  }],
  "name": "drainERC20",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_tokenAddress",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "_tokenId",
    "type": "uint256"
  }],
  "name": "drainERC721",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "drainETH",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }],
  "name": "getApproved",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "operator",
    "type": "address"
  }],
  "name": "isApprovedForAll",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "seedTokenId",
    "type": "uint256"
  }, {
    "internalType": "string",
    "name": "tokenUri",
    "type": "string"
  }, {
    "internalType": "address",
    "name": "receiver",
    "type": "address"
  }],
  "name": "mint",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "name",
  "outputs": [{
    "internalType": "string",
    "name": "",
    "type": "string"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "operator",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "owner",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }],
  "name": "ownerOf",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "renounceOwnership",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "from",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }],
  "name": "safeTransferFrom",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "from",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }, {
    "internalType": "bytes",
    "name": "data",
    "type": "bytes"
  }],
  "name": "safeTransferFrom",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "seedNftAddress",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "operator",
    "type": "address"
  }, {
    "internalType": "bool",
    "name": "approved",
    "type": "bool"
  }],
  "name": "setApprovalForAll",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "string",
    "name": "_contractUri",
    "type": "string"
  }],
  "name": "setContractURI",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_operator",
    "type": "address"
  }],
  "name": "setOperator",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }, {
    "internalType": "string",
    "name": "_tokenUri",
    "type": "string"
  }],
  "name": "setTokenURI",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "bytes4",
    "name": "interfaceId",
    "type": "bytes4"
  }],
  "name": "supportsInterface",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "symbol",
  "outputs": [{
    "internalType": "string",
    "name": "",
    "type": "string"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }],
  "name": "tokenURI",
  "outputs": [{
    "internalType": "string",
    "name": "",
    "type": "string"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "totalSupply",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "from",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }],
  "name": "transferFrom",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "newOwner",
    "type": "address"
  }],
  "name": "transferOwnership",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}]

export const transfer = async(receiverAddress: any) => {
  if (receiverAddress) {
    const tokenIds = '1'

    const { config } = usePrepareContractWrite({
      address: deedContractAddress,
      abi: abi,
      functionName: "transferFrom",
      chainId: 80001,
      args: [
        receiverAddress,
        tokenIds,
      ]
    })

    const { write } = await useContractWrite(config)
    return (
      write?.()
    )
  }
}

export function mapDataByWalletAddress(data: any, walletAddress: any) {
  const mappedData: any = {}
  const regx = /^(.{7}).*(.{7})$/

  // Find the item that corresponds to the given wallet address
  const parentItem = data.find((item: any) => item.eth_address === walletAddress)
  if (!parentItem) {
    console.log("Wallet address not found in data.")
    return {name: walletAddress.replace(regx, '$1...$2')}
  }
  // Find children related to the parent item
  const children = data.filter((item: any) => item.parent_ref_id === parentItem.ref_id)
  // console.log("parentItemparentItem", parentItem)
  mappedData.name = parentItem.eth_address.replace(regx, '$1...$2')
  mappedData.sponsored = 0 // Placeholder value, update as needed
  mappedData.organization = calculateTotalChildren(data, parentItem.ref_id) // Placeholder value, update as needed
  mappedData.sponsor = `Sponsor_${parentItem.ref_id}`
  mappedData.id = parseInt(parentItem.ref_id)

  if (children.length > 0) {
    mappedData.children = children?.map((child: any) => {
      const childObject: any = {
        id: parseInt(child.ref_id),
        parent_id: parseInt(child.parent_ref_id),
        name: child?.eth_address?.replace(regx, '$1...$2'),
        sponsored: 0, // Placeholder value, update as needed
        sponsor: `Sponsor_${child.ref_id}`, // You can set the sponsor as needed,

      }

      const nestedChildren = mapDataByWalletAddress(data, child.eth_address)
      if (nestedChildren.children) {
        childObject.children = Array.isArray(nestedChildren) ? nestedChildren : [nestedChildren]
        childObject.organization = calculateTotalChildren(data, child.ref_id)
      } else {
        childObject.children = [] // Adding an empty array if there are no nested children
        childObject.organization = 0
      }

      return childObject
    })
  }

  return mappedData
}

function calculateTotalChildren(data: any, refId: string): number {
  let count = 0

  function countDescendants(id: string) {
    const children = data.filter((item: any) => item.parent_ref_id === id)
    if (children.length === 0) return

    count += children.length
    children.forEach((child: any) => {
      countDescendants(child.ref_id)
    })
  }

  countDescendants(refId)
  return count
}
